@import '~antd/dist/antd.less';

#root {
    height: 100%;
}

button,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@primary-color: #00b0f0;@blue-base: #00b0f0;@red-base: #e33;@green-base: #2fcc7b;@gold-base: #faad14;@layout-body-background: #fff;@btn-primary-bg: @green-base;@btn-default-ghost-color: @green-base;@btn-default-border: @green-base;@font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';